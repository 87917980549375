<template>
<v-container fluid>

    <v-app-bar>
        <v-app-bar-title>
            <v-btn @click="tab='one'" size="small">Mc Sell</v-btn>
            <v-btn @click="tab='two'" size="small">Mc Buy</v-btn>
            <v-btn @click="tab='three'" size="small">MC Bag</v-btn>
            <v-btn @click="tab='four'" size="small">MC Pay</v-btn>
            <v-btn @click="tab='five'" size="small" v-if="ErrorDesserts.length > 0">Error
                <v-chip size="x-small" color="red-accent-4" variant="flat">
                    {{ ErrorDesserts.length }}
                </v-chip>
            </v-btn>
        </v-app-bar-title>
        <v-app-bar-title class="pa-1 text-right">
            <v-text-field v-model="Search" label="Search" prepend-inner-icon="mdi-magnify" variant="outlined" hide-details single-line></v-text-field>
        </v-app-bar-title>
    </v-app-bar>

    <div v-if="tab=='one'">
        <v-data-table-virtual :search="Search" :headers="Headers" :items="McSellDesserts" height="80vh"></v-data-table-virtual>
    </div>
    <div v-if="tab=='two'">
        <v-data-table-virtual :search="Search" :headers="Headers" :items="McBuyDesserts" height="80vh"></v-data-table-virtual>
    </div>
    <div v-if="tab=='three'">
        <v-data-table-virtual :search="Search" :headers="McBagHeaders" :items="McBagDesserts" height="80vh"></v-data-table-virtual>
    </div>
    <div v-if="tab=='four'">
        <div class="d-flex justify-space-between pa-4 pb-0">
            <v-row>
                <v-col sm="6">
                    <v-autocomplete size="x-small" label="Mc" v-model="McPay" :items="McName">
                        <template v-slot:append>
                            <v-slide-x-reverse-transition mode="out-in">
                                <v-icon color="success" icon="mdi-check-outline" @click="McPayDessert"></v-icon>
                            </v-slide-x-reverse-transition>
                        </template>
                    </v-autocomplete>
                </v-col>
                <v-chip v-if="McBagTotal!=0" prepend-icon="mdi-credit-card" variant="outlined">
                    Mc Bag:{{ McBagTotal }}
                </v-chip>
                <v-chip prepend-icon="mdi-cash-plus" variant="outlined">
                    Mc Pay:{{ McPayTotal }}
                </v-chip>
            </v-row>

            <v-btn icon="mdi-plus" color="success" @click="McPaydialog = true;"></v-btn>

        </div>
        <v-data-table-virtual :headers="McPayHeaders" :items="McPayDesserts" height="80vh"></v-data-table-virtual>

    </div>
    <div v-if="tab=='five'">
        <v-data-table-virtual :headers="Headers" :items="ErrorDesserts" height="80vh">
            <template v-slot:item="{ item }">
                <tr>
                    <!-- 您的数据列 -->
                    <td>{{ item.Date }}</td>
                    <td>{{ item.Description }}</td>
                    <td>{{ item.In }}</td>
                    <td>{{ item.Out }}</td>
                    <td>{{ item.Time }}</td>
                    <td>{{ item.Rate }}</td>
                    <td>{{ item.MYR }}</td>
                    <td>{{ item.CashAt }}</td>
                    <td>{{ item.Remarks }}</td>
                    <!-- 编辑按钮 -->
                    <td>
                        <v-btn @click="ErrorEdit(item)">
                            Edit
                        </v-btn>
                    </td>
                </tr>
            </template>
        </v-data-table-virtual>
    </div>
    <v-dialog persistent v-model="dialog" max-width="600">
        <v-card title="Edit Mc Sell">
            <v-card-text>
                <v-row dense>
                    <v-col cols="12" sm="6">
                        <v-text-field label="Rate*" v-model="McEdit.Rate" required></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-text-field label="Cash At*" v-model="McEdit.CashAt" required></v-text-field>
                    </v-col>
                </v-row>
                <small class="text-caption text-medium-emphasis">*indicates required field</small>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text="Close" variant="plain" @click="McEditClose"></v-btn>
                <v-btn color="primary" text="Save" variant="tonal" @click="McEditSave"></v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <v-dialog persistent v-model="McPaydialog" max-width="600">
        <v-card title="Edit Mc Sell">
            <v-card-text>
                <v-row dense>
                    <v-col cols="12" sm="6">
                        <v-text-field label="Date *" v-model="McPayEdit.Date" type="date" required></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-autocomplete label="Mc *" v-model="McPayEdit.Mc" :items="McEditName" required></v-autocomplete>
                    </v-col>

                    <v-col cols="12" sm="6">
                        <v-text-field v-model="McPayEdit.In" label="In" prefix="MYR"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-text-field v-model="McPayEdit.Out" label="Out" prefix="MYR"></v-text-field>
                    </v-col>
                </v-row>
                <small class="text-caption text-medium-emphasis">*indicates required field</small>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text="Close" variant="plain" @click="McPayEditClose"></v-btn>
                <v-btn color="primary" text="Save" variant="tonal" @click="McPayEditSave"></v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

</v-container>

<!-- <v-fab color="success" icon="mdi-plus" class="ms-4 mb-4" location="bottom start" size="64" absolute app appear></v-fab> -->
</template>

<script>
import axios from 'axios';
import Cookies from 'js-cookie';
export default {
    data() {
        return {
            Search: '',
            tab: 'one',
            dialog: false,
            McPaydialog: false,
            websiteID: Cookies.get('websiteID'),
            Month: Cookies.get('Month'),
            McPay: '',
            McBagTotal: '',
            McPayTotal: '',
            McName: [],
            McEditName: [],
            Headers: [{
                    title: "Date",
                    key: "Date"
                }, {
                    title: "Description",
                    key: "Description"
                },
                {
                    title: "In",
                    key: "In"
                },
                {
                    title: "Out",
                    key: "Out"
                },
                {
                    title: "Time",
                    key: "Time"
                },
                {
                    title: "Rate",
                    key: "Rate"
                },
                {
                    title: "MYR",
                    key: "MYR"
                },
                {
                    title: "Cash At",
                    key: "CashAt"
                },
                {
                    title: "Remarks",
                    key: "Remarks"
                },
            ],
            McPayHeaders: [{
                    title: "Date",
                    key: "Date"
                }, {
                    title: "Mc",
                    key: "Mc"
                },
                {
                    title: "In (MYR)",
                    key: "In"
                },
                {
                    title: "Out  (MYR)",
                    key: "Out"
                },
            ],
            McBagHeaders: [{
                    title: "McName",
                    key: "McName"
                },
                {
                    title: "Amount (MYR)",
                    key: "Amount"
                }
            ],
            McSellDesserts: [],
            McBuyDesserts: [],
            ErrorDesserts: [],
            McBagDesserts: [],
            McEdit: {
                ID: "",
                Func: "",
                CashAt: "",
                Rate: ""
            },
            McPayEdit: {
                Date: "",
                Mc: "",
                In: 0,
                Out: 0,
            }
        };
    },
    created() {
        this.Update();
    },

    methods: {
        Update() {
            this.Desserts();
            this.McPayDessert();
            this.CheckMcName();
        },

        async Desserts() {
            const formData = new FormData();

            formData.append('Func', "DailyMcSell");
            formData.append('WebID', this.websiteID);
            formData.append('Month', this.Month);
            axios.post('https://auto.amoikiss.com/API/McDesserts.php', formData, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
            }).then(response => {
                this.McSellDesserts = response.data;
            });
            formData.append('Func', "DailyMcBuy");
            axios.post('https://auto.amoikiss.com/API/McDesserts.php', formData, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
            }).then(response => {
                this.McBuyDesserts = response.data;
            });

            formData.append('Func', "DailyMcBuy");
            axios.post('https://auto.amoikiss.com/API/McBagDesserts.php', formData, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
            }).then(response => {
                this.McBagDesserts = response.data;
            });
            axios.post('https://auto.amoikiss.com/API/McErrorDesserts.php', formData, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
            }).then(response => {
                this.ErrorDesserts = response.data;
            });

        },
        async McPayDessert() {

            const formData = new FormData();

            formData.append('WebID', this.websiteID);
            formData.append('Mc', this.McPay);
            formData.append('Month', this.Month);
            axios.post('https://auto.amoikiss.com/API/McPayDesserts.php', formData, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
            }).then(response => {
                this.McPayDesserts = response.data;
            });
            axios.post('https://auto.amoikiss.com/API/McPayTotal.php', formData, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
            }).then(response => {
                // console.log(response.data)
                this.McPayTotal = response.data[0];
                this.McBagTotal = response.data[1];
            });

        },
        async CheckMcName() {
            const formData = new FormData();

            formData.append('WebID', this.websiteID);

            axios.post('https://auto.amoikiss.com/API/McName.php', formData, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
            }).then(response => {
                this.McEditName = response.data.map(item => item.McName);
                this.McName = response.data.map(item => item.McName);
                this.McName.push("All");
            });
        },
        ErrorEdit(item) {
            this.dialog = true;
            this.McEdit.ID = item.ID;
            this.McEdit.Func = item.Func;
            this.McEdit.Rate = item.Rate;
            this.McEdit.CashAt = item.CashAt;

        },
        McEditClose() {
            this.dialog = false;
            this.McEdit.ID = '';
            this.McEdit.Func = '';
            this.McEdit.Rate = '';
            this.McEdit.CashAt = '';
        },
        McPayEditClose() {
            this.McPaydialog = false;
            this.McPayEdit.Date = '';
            this.McPayEdit.Mc = '';
            this.McPayEdit.In = 0;
            this.McPayEdit.Out = 0;
        },
        McPayEditSave() {
            const formData = new FormData();

            formData.append('WebID', this.websiteID);
            formData.append('Date', this.McPayEdit.Date);
            formData.append('Mc', this.McPayEdit.Mc);
            formData.append('In', this.McPayEdit.In);
            formData.append('Out', this.McPayEdit.Out);

            axios.post('https://auto.amoikiss.com/API/McPayInsertData.php', formData, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
            }).then(response => {
                if (response.data == "Done") {
                    this.McPayEditClose();
                    this.McPayDessert();
                }
            });

        },
        McEditSave() {
            const formData = new FormData();

            formData.append('ID', this.McEdit.ID);
            formData.append('Func', this.McEdit.Func);
            formData.append('Rate', this.McEdit.Rate);
            formData.append('CastAt', this.McEdit.CashAt);

            axios.post('https://auto.amoikiss.com/API/McEditData.php', formData, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
            }).then(response => {
                if (response.data == "Done") {
                    this.McEditClose();
                    this.Desserts();
                }
            });

        },

    }
};
</script>
