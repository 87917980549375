import { createRouter, createWebHistory } from 'vue-router'
import Monthly from '@/views/MonthlyPage.vue'
import Daily from '@/views/DailyPage.vue'
import ExpenseOther from '@/views/ExpenseOther.vue'
import McProject from '@/views/McProject.vue'
import Login from '@/views/LoginPage.vue'
import Admin from '@/views/AdminPage.vue'

const routes = [
    { path: '/', component: Monthly },
    { path: '/Daily', component: Daily },
    { path: '/ExpenseOther', component: ExpenseOther },
    { path: '/Mc', component: McProject },
    { path: '/Login', component: Login },
    { path: '/Admin', component: Admin },
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

export default router
