<template>
<v-app id="inspire">

    <v-navigation-drawer v-model="drawer">
        <v-list-item color="grey-lighten-4" class="pa-4 text-none-select" :subtitle="AccountType" :title="AccountName">
            <template v-slot:prepend>
                <v-avatar color="red">
                    <v-icon color="white">mdi-account</v-icon>
                </v-avatar>
            </template>

        </v-list-item>

        <v-list>
            <v-list-item v-for="[icon, text, link] in filteredLinks" :key="icon" :prepend-icon="icon" :title="text" link @click="Link(link)"></v-list-item>

        </v-list>
        <v-divider></v-divider>
        <v-list density="compact" nav>
            <v-list-item prepend-icon="mdi-logout" title="Logout" value="Logout" @click="Logout"></v-list-item>
        </v-list>
    </v-navigation-drawer>

    <v-app-bar v-if="bar">
        <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

        <v-app-bar-title>
            {{ FuncName }}

        </v-app-bar-title>

        <v-app-bar-title class="pa-4 text-right">
            <v-chip class="mr-2">
                Month:{{ Month }}
            </v-chip>
            <v-chip class="mr-2">
                Website:{{ Website }}
            </v-chip>
            <v-btn icon size="small" @click="SettingDialog=true">
                <v-avatar color="brown">
                    <span class="mdi mdi-lead-pencil"></span>
                </v-avatar>
            </v-btn>
        </v-app-bar-title>
    </v-app-bar>

    <v-main>
        <!-- {{ currentPage }} -->
        <router-view></router-view>

    </v-main>
    <v-dialog persistent v-model="SettingDialog" max-width="600">
        <v-card title="Setting">

            <v-card-text>
                <v-row dense>
                    <v-col cols="12">

                    </v-col>

                    <v-col cols="12">
                        <v-autocomplete label="Website" :items="websiteOptions" v-model="Website"></v-autocomplete>
                    </v-col>
                    <v-col cols="12">
                        <v-autocomplete label="Month" :items="['01','02','03','04','05','06','07','08','09','10','11','12']" v-model="Month"></v-autocomplete>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text="Close" variant="plain" @click="SettingClose"></v-btn>
                <v-btn color="primary" text="Save" variant="tonal" @click="SettingSave"></v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</v-app>
</template>

<script>
import axios from 'axios';
import Cookies from 'js-cookie';
export default {
    data() {
        return {
            WebList: [],
            Website: '',
            Month: Cookies.get('Month'),
            websiteID: Cookies.get('websiteID'),
            SettingDialog: false,
            drawer: null,
            bar: true,
            links: [
                ['mdi-alpha-a-circle-outline', 'Admin', '/Admin'],
                ['mdi-alpha-m-circle-outline', 'Monthly', '/'],
                ['mdi-alpha-d-circle-outline', 'Daily', '/Daily'],
                ['mdi-alpha-m-circle-outline', 'Mc', '/Mc'],
                ['mdi-alpha-e-circle-outline', 'Expense Other', '/ExpenseOther'],
            ],
            // currentPage: '',
            AccountID: localStorage.getItem('AccountID'),
            AccountName: localStorage.getItem('AccountName'),
            AccountType: localStorage.getItem('AccountType'),
            AccountWebsite: localStorage.getItem('AccountWebsite'),
        };
    },
    created() {
        if (this.AccountID != null) {
            // this.checkAccount();
        } else {
            this.bar = false;
            this.drawer = false;
            this.Link("Login");
        }

        if (this.Month == null) {
            Cookies.set('Month', String((new Date()).getMonth() + 1).padStart(2, '0'), {
                expires: 1
            });
        }
        this.CheckWebList();
    },

    computed: {

        filteredLinks() {
            return this.links.filter(([icon, text, link]) => {
                icon,link
                return this.AccountType === '1' || text !== 'Admin';
            });
        },
        FuncName() {
            // 根据当前页面的路径动态确定 FuncName 的值
            const currentPagePath = this.$route.path;
            const matchedLink = this.links.find(link => link[2] === currentPagePath);
            return matchedLink ? matchedLink[1] : '';
        },
        websiteOptions() {
            return this.WebList.map(item => item.Name);
        }
    },
    methods: {

        shouldDisplayLink(linkText) {
            return this.AccountType === '1' && linkText === 'Admin';
        },
        SettingClose() {
            this.SettingDialog = false;
        },
        SettingSave() {
            const websiteObject = this.WebList.find(item => item.Name === this.Website);
            const websiteID = websiteObject ? websiteObject.ID : '0'; // Get the ID

            Cookies.set('websiteID', websiteID, {
                expires: 1
            });
            Cookies.set('Month', this.Month, {
                expires: 1
            });
            // Cookies.remove('Month');
            window.location.reload();
        },
        async CheckWebList() {
            const formData = new FormData();
            formData.append('WebID', this.AccountWebsite);
            axios.post('https://auto.amoikiss.com/API/WebsiteList_account.php', formData, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
            }).then(response => {
                this.WebList = response.data;

                if (this.websiteID == null) {
                    if (this.AccountType == 0) {
                        this.websiteID = 1;

                    } else {
                        this.websiteID = this.AccountWebsite;

                    }
                }
                Cookies.set('websiteID', this.websiteID, {
                    expires: 1
                });

                this.Website = null; // 初始化为 null
                for (let i = 0; i < response.data.length; i++) {
                    if (response.data[i].ID == this.websiteID) {
                        this.Website = response.data[i].Name;
                        break; // 找到匹配项后停止循环
                    }
                }

            });
        },
        Link(i) {
            this.$router.push(i);
        },
        Logout() {
            localStorage.removeItem('AccountID');
            localStorage.removeItem('AccountName');
            localStorage.removeItem('AccountType');
            localStorage.removeItem('AccountWebsite');

            // 跳转到登录页面或其他适当的页面
            window.location.href = "/";

        }
    },
}
</script>

<style>
.Pencil {
    position: fixed !important;
    bottom: 5%;
    right: 2%;
}

.text-none-select {
    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE 10+ */
    user-select: none;
    /* Standard syntax */
}
</style>
