<template>
<v-container fluid>
    <v-app-bar>
        <v-app-bar-title>
            <v-btn @click="tab='one'" size="small">Expenses</v-btn>
            <v-btn @click="tab='two'" size="small">Other</v-btn>
        </v-app-bar-title>
        <v-app-bar-title class="pa-1 text-right">
            <v-text-field v-model="Search" label="Search" prepend-inner-icon="mdi-magnify" variant="outlined" hide-details single-line></v-text-field>
        </v-app-bar-title>
    </v-app-bar>
    <div v-if="tab=='one'">
        <v-data-table-virtual :search="Search" :headers="Headers" :items="ExpenseDesserts" height="80vh"></v-data-table-virtual>
    </div>
    <div v-if="tab=='two'">
        <v-data-table-virtual :search="Search" :headers="Headers" :items="OtherDesserts" height="80vh"></v-data-table-virtual>
    </div>

</v-container>
</template>

<script>
import axios from 'axios';
import Cookies from 'js-cookie';
export default {
    data() {
        return {
            Search:'',
            tab: 'one',
            websiteID: Cookies.get('websiteID'),
            Month: Cookies.get('Month'),
            Headers: [{
                    title: "Date",
                    key: "Date"
                }, {
                    title: "Description",
                    key: "Description"
                },
                {
                    title: "In",
                    key: "In"
                },
                {
                    title: "Out",
                    key: "Out"
                },
                {
                    title: "Time",
                    key: "Time"
                },
                {
                    title: "Remarks",
                    key: "Remarks"
                },
            ],
            ExpenseDesserts: [],
            OtherDesserts: [],
        };
    },
    created() {
        this.Update();
    },
    methods: {
        Update() {
            this.EODesserts();
        },

        async EODesserts() {
            const formData = new FormData();

            formData.append('Func', "DailyExpenses");
            formData.append('WebID', this.websiteID);
            formData.append('Month', this.Month);
            axios.post('https://auto.amoikiss.com/API/EODesserts.php', formData, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
            }).then(response => {
                this.ExpenseDesserts = response.data;
            });

            formData.append('Func', "DailyOther"); // Use the ID
            axios.post('https://auto.amoikiss.com/API/EODesserts.php', formData, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
            }).then(response => {

                this.OtherDesserts = response.data;
            });

        },

    }
};
</script>
