<template>
<v-container fluid>

    <v-app-bar>
        <v-app-bar-title>
            <v-btn @click="tab='one'" size="small">Total Amount</v-btn>
            <v-btn @click="tab='two'" size="small">Closing</v-btn>
            <v-btn @click="tab='three'" size="small">Summary</v-btn>
            <v-btn @click="tab='four'" size="small">Setting
                <v-chip v-if="ClassifyNull!=0" size="x-small" color="red-accent-4" variant="flat">
                    {{ ClassifyNull }}
                </v-chip>
            </v-btn>
        </v-app-bar-title>
        <v-app-bar-title class="pa-1 text-right">
            <v-text-field v-model="Search" label="Search" prepend-inner-icon="mdi-magnify" variant="outlined" hide-details single-line></v-text-field>
        </v-app-bar-title>
    </v-app-bar>
    <div v-if="tab=='one'">
        <v-data-table-virtual :search="Search" :headers="Headers" :items="TotalAmountDesserts" height="80vh" loading-text="Loading... Please wait" :loading="loading"></v-data-table-virtual>
    </div>
    <div v-if="tab=='two'">
        <v-data-table-virtual :search="Search" :headers="Headers" :items="ClosingDesserts" height="80vh"></v-data-table-virtual>
    </div>
    <div v-if="tab=='three'">
        <v-data-table-virtual :search="Search" :headers="Headers" :items="SummaryDesserts" height="80vh"></v-data-table-virtual>
    </div>
    <div v-if="tab=='four'">
        <v-btn-toggle v-if="selectedMember.length > 0">
            <v-btn variant="outlined" @click="UpdateMemberClassify('Aud At Other')"> Aud At Other </v-btn>
            <v-btn variant="outlined" @click="UpdateMemberClassify('Aud At Bank')"> Aud At Bank</v-btn>
        </v-btn-toggle>
        <v-data-table-virtual :search="Search" fixed-header v-model="selectedMember" show-select :headers="MemberClassifyHeaders" :items="MemberClassifyDesserts" :sort-by="[{ key: 'Classify', order: 'asc' }]" item-value="Name" height="80vh"></v-data-table-virtual>
    </div>

</v-container>
</template>

<script>
import axios from 'axios';
import Cookies from 'js-cookie';
export default {
    data() {
        return {
            Search: '',
            tab: 'one',
            loading: false,
            websiteID: Cookies.get('websiteID'),
            Month: Cookies.get('Month'),
            Headers: [],
            ClosingDesserts: [],
            SummaryDesserts: [],
            TotalAmountDesserts: [],
            MemberClassifyHeaders: [{
                title: 'Name',
                key: 'Name'
            }, {
                title: 'Classify',
                key: 'Classify'
            }],
            MemberClassifyDesserts: [],
            selectedMember: [],
            ClassifyNull: 0,
        };
    },
    created() {

        this.Update();

    },
    methods: {
        Update() {
            this.DailyHeader();
            this.DailyDesserts();
            this.CheckMemberClassify();
        },
        async DailyHeader() {
            const formData = new FormData();

            formData.append('Func', "DailyClosing");
            formData.append('WebID', this.websiteID);
            formData.append('Month', this.Month);
            axios.post('https://auto.amoikiss.com/API/DailyHeader.php', formData, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
            }).then(response => {
                this.Headers = response.data;
            });

        },
        async DailyDesserts() {
            const formData = new FormData();

            formData.append('Func', "DailyClosing");
            formData.append('WebID', this.websiteID);
            formData.append('Month', this.Month);
            axios.post('https://auto.amoikiss.com/API/DailyDesserts.php', formData, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
            }).then(response => {
                this.ClosingDesserts = response.data;
            });

            formData.append('Func', "DailySummary"); // Use the ID
            axios.post('https://auto.amoikiss.com/API/DailyDesserts.php', formData, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
            }).then(response => {
                this.SummaryDesserts = response.data;
            });
            formData.append('Func', "DailySummary"); // Use the ID
            this.loading = true;
            axios.post('https://auto.amoikiss.com/API/DailyTotalAmountDesserts.php', formData, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
            }).then(response => {
                // console.log(response.data)
                this.TotalAmountDesserts = response.data;
                this.loading = false;
            });

        },
        async CheckMemberClassify() {
            const formData = new FormData();

            formData.append('WebID', this.websiteID);
            axios.post('https://auto.amoikiss.com/API/CheckMemberClassify.php', formData, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
            }).then(response => {
                this.MemberClassifyDesserts = response.data;
                let nullCount = 0;
                for (let i = 0; i < this.MemberClassifyDesserts.length; i++) {
                    if (!this.MemberClassifyDesserts[i].Classify) {
                        nullCount++;
                    }
                }
                this.ClassifyNull = nullCount;
            });
        },
        async UpdateMemberClassify(i) {
            const formData = new FormData();

            formData.append('WebID', this.websiteID);
            formData.append('selectedMember', this.selectedMember);
            formData.append('Classify', i);

            axios.post('https://auto.amoikiss.com/API/UpdateMemberClassify.php', formData, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
            }).then(response => {
                // console.log(response.data);
                if (response.data == "Done") {
                    this.CheckMemberClassify();
                    this.selectedMember = [];
                }
            });

        },
    }
};
</script>
