<template>
<v-container fluid>

    <v-app-bar>
        <v-app-bar-title>
            <v-btn @click="tab='one'" size="small">Day</v-btn>
            <v-btn @click="tab='two'" size="small">Web</v-btn>
            <v-btn @click="tab='three'" size="small">Error</v-btn>
        </v-app-bar-title>
        <v-app-bar-title class="pa-1 text-right">
            <v-text-field v-model="Search" label="Search" prepend-inner-icon="mdi-magnify" variant="outlined" hide-details single-line></v-text-field>
        </v-app-bar-title>
    </v-app-bar>
    <div v-if="tab=='one'">
        <v-data-table-virtual :search="Search" :headers="Headers" :items="DayDesserts" height="80vh" loading-text="Loading... Please wait" :loading="loading"></v-data-table-virtual>
    </div>
    <div v-if="tab=='two'">
        <v-data-table-virtual :search="Search" :headers="Headers" :items="WebDesserts" height="80vh"></v-data-table-virtual>
    </div>
    <div v-if="tab=='three'">
        <v-data-table-virtual :search="Search" :headers="ErrorHeaders" :items="ErrorDesserts" height="80vh"></v-data-table-virtual>
    </div>

</v-container>
</template>

<script>
import axios from 'axios';
import Cookies from 'js-cookie';
export default {
    data() {
        return {
            Search: '',
            tab: 'one',
            loading: false,
            websiteID: Cookies.get('websiteID'),
            Month: Cookies.get('Month'),

            Headers: [{
                    title: '#',
                    align: 'start',
                    key: '#'
                },
                {
                    title: 'Deposit',
                    key: 'Deposit1'
                },
                {
                    title: 'Deposit',
                    key: 'Deposit2'
                },
                {
                    title: 'Withdraw',
                    key: 'Withdraw1'
                },
                {
                    title: 'Withdraw',
                    key: 'Withdraw2'
                },
                {
                    title: 'Net',
                    key: 'Net'
                },
                {
                    title: 'New',
                    key: 'New'
                },
                {
                    title: 'FTD',
                    key: 'FTD'
                },
                {
                    title: 'RTD',
                    key: 'RTD'
                },
                {
                    title: 'Active',
                    key: 'Active'
                },
            ],
            ErrorHeaders:[{
                    title: 'Date',
                    key: 'Date'
                },
                {
                    title: 'Error Website',
                    key: 'Data'
                },
            ],
            DayDesserts: [],
            WebDesserts: [],
            ErrorDesserts: []
        };
    },
    created() {
        this.ReadAdminDay();
        this.ReadAdminWeb();
        this.ReadAdminError();
        

    },
    methods: {

        async ReadAdminDay() {
            const formData = new FormData();

            formData.append('Month', this.Month);
            axios.post('https://auto.amoikiss.com/API/AdminDay.php', formData, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
            }).then(response => {
                this.DayDesserts = response.data;
            });

        },
        async ReadAdminWeb() {
            const formData = new FormData();

            formData.append('Month', this.Month);
            axios.post('https://auto.amoikiss.com/API/AdminWeb.php', formData, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
            }).then(response => {
                this.WebDesserts = response.data;
            });

        },
        async ReadAdminError() {
            const formData = new FormData();

            formData.append('Month', this.Month);
            axios.post('https://auto.amoikiss.com/API/AdminError.php', formData, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
            }).then(response => {
                console.log(response.data)
                this.ErrorDesserts = response.data;
            });

        },
    }
};
</script>
