<template>
<v-container fluid>
    <v-text-field class="ma-2" v-model="Search" label="Search" prepend-inner-icon="mdi-magnify" variant="outlined" hide-details single-line></v-text-field>
    <v-data-table-virtual :search="Search" :headers="headers" :items="Desserts" :sort-by="[{ key: 'Date', order: 'asc' }]" height="85vh"></v-data-table-virtual>
</v-container>
</template>

<script>
import axios from 'axios';
import Cookies from 'js-cookie';
export default {
    data() {
        return {
            Search: '',
            websiteID: Cookies.get('websiteID'),
            Month: Cookies.get('Month'),
            headers: [{
                    title: 'Date',
                    align: 'start',
                    key: 'Date'
                },
                {
                    title: 'Deposit',
                    key: 'Deposit1'
                },
                {
                    title: 'Deposit',
                    key: 'Deposit2'
                },
                {
                    title: 'Withdraw',
                    key: 'Withdraw1'
                },
                {
                    title: 'Withdraw',
                    key: 'Withdraw2'
                },
                {
                    title: 'Net',
                    key: 'Net'
                },
                {
                    title: 'New',
                    key: 'New'
                },
                {
                    title: 'FTD',
                    key: 'FTD'
                },
                {
                    title: 'RTD',
                    key: 'RTD'
                },
                {
                    title: 'Active',
                    key: 'Active'
                },
            ],
            Desserts: []
        };
    },
    created() {
        this.CheckMonthly();

    },
    methods: {
        
        async CheckMonthly() {
            const formData = new FormData();

            formData.append('WebID', this.websiteID);
            formData.append('Month', this.Month);
            axios.post('https://auto.amoikiss.com/API/CheckMonthly.php', formData, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
            }).then(response => {
                this.Desserts = response.data;
            });
        }
    }
};
</script>

<style>
thead {
    position: sticky;
    top: 0;
    z-index: 2;
    background-color: rgb(77, 77, 77)!important;
    color: #ddd;
    font-weight: 700!important;
    font-size: 1.2em!important;
}

table {
    width: 100%;
    border-collapse: collapse;
}

th,
td {
    padding: 8px;
    text-align: left;
    border-right: 1px solid #ddd;
    /* 添加每列的右侧边框线 */
    border-bottom: 1px solid #ddd;
}

th:last-child,
td:last-child {
    border-right: none;
}

th:first-child,
td:first-child {
    position: sticky;
    left: 0; /* 将第一列固定在左侧 */
    background-color: rgb(77, 77, 77)!important;
    color: #ddd;
    z-index: 1; /* 将固定列置于其他内容之上 */
}
/* Alternating row colors */
tbody tr:nth-child(even) {
    background-color: #f2f2f2;
}
</style>
